<template>
  <DashboardSettingsOverlayStyled>
    <Modal class="modal" :errors="errors">
      <template v-slot:header>
        {{ $t(headerTitle) }}
      </template>
      <template v-slot:main>
        <FormStyled>
          <FormRowStyled>
            <InputStyled>
              <input ref="nameInput" v-model.trim="$v.title.$model" :placeholder="$tc('name', 1)" />
              <div class="error" v-if="$v.title.$dirty && !$v.title.required">{{ $tc('messages.required') }}</div>
              <div class="error" v-if="!$v.title.minLength">{{ $t('messages.minText', { value: $v.title.$params.minLength.min }) }}</div>
            </InputStyled>
          </FormRowStyled>
          <FormRowStyled>
            <BasicSelectMolecule
              :target="$tc('dashboard.types.placeholder')"
              :options="dashboardTypes"
              :selected="selectedDashboardType"
              @change="setSelectedDashboardType"
            />
          </FormRowStyled>
          <TalpaLoaderWrapper v-if="$apollo.queries.assets.loading" class="loader" />
          <template v-else>
            <FormRowStyled>
              <BasicSelectMolecule
                :target="$tc('dashboard.types.placeholderFilter')"
                :options="dashboardFilters"
                :selected="selectedDashboardAssetFilter"
                @change="setSelectedDashboardAssetFilter"
              />
            </FormRowStyled>
            <FormRowStyled
              v-if="
                selectedDashboardAssetFilter && selectedDashboardAssetFilter.id !== 'NONE' && selectedDashboardAssetFilter.id !== 'MANUAL'
              "
            >
              <Multiselect
                v-model="groupedData"
                :multiple="true"
                :options="groupedAssetMetaData"
                :loading="$apollo.queries.assets.loading"
                :showLabels="false"
                :close-on-select="false"
                :placeholder="groupByPlaceholderValue"
                @select="handleSelectedGroupedData"
                @remove="handleRemoveGroupedData"
                trackBy="id"
                label="name"
              >
                <template slot="option" slot-scope="{ option }">
                  <div class="label">{{ option.name }} <CircleBadge class="badge" :label="option.assetCount" size="M" /></div>
                </template>
              </Multiselect>
              <ClearAssetsStyled @click="clearGroupBy()">
                <XIcon />
              </ClearAssetsStyled>
            </FormRowStyled>
            <FormRowStyled v-if="showAssetSelection">
              <Multiselect
                v-model="selectedAssets"
                :multiple="true"
                :options="assets"
                :loading="$apollo.queries.assets.loading"
                :showLabels="false"
                :placeholder="placeholderValue"
                :close-on-select="false"
                :hideSelected="true"
                @select="handleSelectAssets"
                @remove="handleRemoveAssets"
                trackBy="id"
                label="name"
              />
              <ClearAssetsStyled @click="clearAssets">
                <XIcon />
              </ClearAssetsStyled>
            </FormRowStyled>
            <InfoRowStyled v-if="selectedDashboardAssetFilter && selectedDashboardAssetFilter.id === 'NONE'">
              <InfoIcon size="1.5x" /> {{ $t('selectedAssetsInfo', { x: assets.length }) }}
            </InfoRowStyled>
            <InfoRowStyled v-if="errorMessage"> <InfoIcon size="1.5x" /> {{ errorMessage }} </InfoRowStyled>
            <FleetOverview v-if="uniqueSelectedAssets.length > 0">
              <span class="title">
                {{ $tc('selectedAssets', uniqueSelectedAssets.length) }}
                <CircleBadge class="badge" :label="uniqueSelectedAssets.length" size="M" />
              </span>
              <ul>
                <li :key="index" v-for="(asset, index) in uniqueSelectedAssets">
                  {{ asset.name }}
                </li>
              </ul>
            </FleetOverview>
            <FormRowStyled v-if="false">
              <ImagePreviewStyled v-if="image && image.url">
                <img :src="image.url" />
              </ImagePreviewStyled>
              <FileInputStyled v-else @click="triggerFileUpload">
                <ImageIcon />
                <input ref="fileUpload" type="file" @change="upload" />
              </FileInputStyled>
            </FormRowStyled>
          </template>
        </FormStyled>
      </template>
      <template v-slot:footer>
        <ActionsStyled>
          <ButtonSolidStyled v-if="mode === 'create'" @click="createDashboard" :disabled="createDisabled">
            {{ $t('actions.create') }}
          </ButtonSolidStyled>
          <ButtonSolidStyled v-else @click="updateDashboard" :disabled="createDisabled">
            {{ $t('actions.ok') }}
          </ButtonSolidStyled>
          <ButtonBorderedStyled @click="$emit('close')">
            {{ $t('actions.cancel') }}
          </ButtonBorderedStyled>
        </ActionsStyled>
      </template>
    </Modal>
  </DashboardSettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { nanoid } from 'nanoid'
import { get, orderBy, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { ImageIcon, XIcon, InfoIcon } from 'vue-feather-icons'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'
import { ButtonBordered as ButtonBorderedStyled, ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import { Modal } from '@common/components'
import { getUserIdFromToken } from '@common/utils'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import { TalpaLoaderWrapper } from '@common/components'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import BasicSelectMolecule from '@/components/Atomic/Molecules/BasicSelectMolecule'

import DASHBOARDS_UPDATE_MUTATION from '#//graphql/operations/dashboard/updateDashboard.gql'
import CREATE_DASHBOARD_MUTATION from '#/graphql/operations/dashboard/createDashboard.gql'
import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import DASHBOARDS_QUERY from '#/graphql/operations/dashboards/dashboards.gql'
import DASHBOARDS_MINIMAL from '#/graphql/operations/dashboards/dashboardsMinimal.gql'
import DASHBOARD_TYPES_AVAILABLE_QUERY from '#/graphql/misc/dashboardTypesAvailable.gql'
import MY_SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/mySubsidiariesWithAssetsQuery.gql'

const DashboardSettingsOverlayStyled = styled('div')`
  width: 100%;
  height: calc(100% - 50px - 1rem);
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    height: 100%;
  }
`

const FormStyled = styled('div')`
  width: 100%;
  max-width: 400px;
  ${flexColumns}
  >.loader {
    margin: 5rem 0;
  }
`

const FormRowStyled = styled('div')`
  ${flexCenter}
  margin-top: 2rem;
  .basic-select {
    width: 100%;
  }
  &:first-child {
    margin-top: 0;
  }
  > .multiselect {
    > .multiselect__content-wrapper {
      max-height: 290px !important;
    }
    > .multiselect__tags {
      max-height: 8rem;
      overflow: auto;
    }
    .multiselect__option--highlight {
      background: ${p => p.theme.colors.primary};
    }
    .label {
      display: flex;
      justify-content: space-between;
      .badge {
        background: ${p => p.theme.colors.widgetBG};
      }
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
`

const ClearAssetsStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  background: ${({ theme }) => theme.colors.selectBG};
  width: 2.5rem;
  height: 2.5rem;
`

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`

const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${p => p.theme.colors.primary};
    background: ${p => chroma(p.theme.colors.inputBG).alpha(0.1).css()};
    border-bottom: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
    transition: border-color 0.5s ease;
    &:focus {
      border-color: ${p => p.theme.colors.primary};
    }
    &::placeholder {
      color: ${p => p.theme.colors.primary};
    }
  }
`

const FileInputStyled = styled('div')`
  ${flexCenter}
  ${buttonReset}
  width: 100px;
  height: 60px;
  padding: 1rem;
  color: ${p => p.theme.colors.primary}
  border: 1px solid ${p => chroma(p.theme.colors.primary).alpha(0.1).css()};
  border-radius: .5rem;
  transition: border-color .25s ease;

  &:hover, &:focus {
    border-color: ${p => p.theme.colors.primary};
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const ImagePreviewStyled = styled('div')`
  ${flexCenter}
  img {
    max-width: 200px;
  }
`
const FleetOverview = styled('div')`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  > .title {
    flex-grow: 1;
    display: flex;
    font-weight: bold;
    text-transform: capitalize;
    align-items: center;
    > .badge {
      margin-left: 0.5rem;
    }
  }

  > ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 0rem 0 2rem;
    max-height: calc(100% - 2rem);
    overflow: auto;
    justify-content: center;
    grid-column-gap: 2rem;
  }
`
const InfoRowStyled = styled('div')`
  ${flexCenter}
  margin-top: 2rem;
  background: ${p => p.theme.colors.atomic.tableTotal};
  height: 2rem;
  border-radius: 2rem;
  gap: 0.5rem;
`

export default {
  mixins: [validationMixin],
  validations: {
    title: {
      required,
      minLength: minLength(3),
    },
  },
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    DashboardSettingsOverlayStyled,
    ActionsStyled,
    ButtonSolidStyled,
    ButtonBorderedStyled,
    Modal,
    InputStyled,
    FormRowStyled,
    FormStyled,
    Multiselect,
    ImageIcon,
    ClearAssetsStyled,
    XIcon,
    FileInputStyled,
    ImagePreviewStyled,
    BasicSelectMolecule,
    FleetOverview,
    CircleBadge,
    TalpaLoaderWrapper,
    InfoRowStyled,
    InfoIcon,
  },

  data() {
    return {
      dashboards: [],
      assets: [],
      dashboardTypesAvailable: {},
      errors: [],
      image: null,
      isCreating: false,
      profile: {},
      selectedAssets: [],
      selectedDashboardType: {
        id: 'FLEET',
        label: this.$t(`dashboard.types.fleet`),
      },
      dashboardID: null,
      title: '',
      mode: 'create',
      createAssetsList: [],
      removeAsssetsList: [],
      createGroupByList: [],
      removeGroupByList: [],
      selectedDashboardAssetFilter: null,
      groupedData: [],
      mySubsidiaries: [],
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      queriesLoaded: {
        assets: false,
        myAssetsSite: false,
        oems: false,
        mySubsidiaries: false,
      },
    }
  },
  watch: {
    dashboardTypesAvailable: {
      handler({ enumValues }) {
        if (enumValues && !this.selectedDashboardType) {
          const found = enumValues.find(enumValue => enumValue.name === 'FLEET')
          if (!found) {
            return
          }
          this.selectedDashboardType = {
            id: found.name,
            label: this.$t(`dashboard.types.${found.name.toLowerCase()}`),
          }
        }
      },
      immediate: true,
    },
    args: {
      handler(dashboard) {
        this.mode = dashboard.id ? 'edit' : 'create'
        this.title = dashboard.title
        this.dashboardID = dashboard.id
        this.image = dashboard.picture
        if (dashboard.type) {
          this.selectedDashboardType = {
            id: dashboard.type,
            label: this.$t(`dashboard.types.${dashboard.type.toLowerCase()}`),
          }
        }
        if (dashboard.assetFilter) {
          this.selectedDashboardAssetFilter = {
            id: dashboard.assetFilter,
            label: this.$t(`dashboard.filters.${dashboard.assetFilter}`),
          }
        }
        if (dashboard.assetGroups && this.selectedDashboardAssetFilter.id === dashboard.assetFilter) {
          this.groupedData = dashboard.assetGroups.map(group => {
            return {
              id: group.groupType === 'MachineType' || group.groupType === 'MachineModel' ? parseInt(group.groupId) : group.groupId,
              name: group.groupName,
              __typename: group.groupType,
            }
          })
        }
      },
      immediate: true,
    },
    title: {
      handler() {
        if (this.dashboards) {
          if (this.titleExists) {
            const errorExists = this.errors.some(error => error.type === 'title-exists')
            if (!errorExists) {
              this.errors.push({
                id: nanoid(),
                type: 'title-exists',
                rawError: this.$t(`dashboard.errors.titleExists`),
              })
            }
          } else {
            this.errors = this.errors.filter(error => error.type !== 'title-exists')
          }
        }
      },
    },
    allQueriesLoaded(newVal) {
      if (newVal) {
        this.handleAllQueriesLoaded()
      }
    },
  },
  mounted() {
    this.$refs.nameInput.focus()
    if (this.mode === 'create') {
      this.selectedDashboardAssetFilter = this.dashboardFilters[0]
    }
  },
  computed: {
    allQueriesLoaded() {
      // Check if all values in queriesLoaded are true
      return Object.values(this.queriesLoaded).every(Boolean)
    },
    dashboardAssetFilters() {
      const assets = this.assetsFiltered
      // Helper function to count unique values based on a nested key
      const uniqCount = key => {
        const uniqueValues = new Set(assets.map(asset => key.split('.').reduce((obj, k) => obj?.[k], asset)))
        return uniqueValues.size
      }
      return [
        {
          id: 'MANUAL',
          label: 'MANUAL', // CUSTOM
        },
        {
          id: 'ASSET_TYPE',
          label: 'ASSET_TYPE',
          count: uniqCount('type.name'),
        },
        {
          id: 'SITE',
          label: 'SITE',
          count: uniqCount('site.siteId'),
        },
        {
          id: 'MODEL',
          label: 'MODEL',
          count: uniqCount('model.id'),
        },
        {
          id: 'OEM',
          label: 'OEM',
          count: uniqCount('oem.id'),
        },
      ]
    },
    errorMessage() {
      if (this.selectedDashboardAssetFilter?.id === 'MANUAL' && this.selectedAssets.length < 1) {
        const t = get(this.selectedDashboardType, 'id', 'NONE')
        if (t === 'FLEET') {
          return this.$tc('assetNotSelectedInfo', 2)
        } else {
          return this.$tc('assetNotSelectedInfo', 1)
        }
      } else if (
        this.selectedDashboardAssetFilter?.id !== 'NONE' &&
        this.selectedDashboardAssetFilter?.id !== 'MANUAL' &&
        this.groupedData.length < 1
      ) {
        return this.$t('assetGroupNotSelectedInfo')
      } else {
        return ''
      }
    },
    titleExists() {
      return this.dashboards.filter(d => d.id !== this.dashboardID).find(d => d.title === this.title)
    },
    headerTitle() {
      const mode = this.mode === 'create' ? 'add' : 'edit'
      return `titles.${mode}Dashboard`
    },
    createDisabled() {
      return (
        get(this.title, 'length', 0) < 3 ||
        this.titleExists ||
        this.isCreating ||
        (this.selectedDashboardAssetFilter?.id !== 'NONE' &&
          this.selectedDashboardAssetFilter?.id !== 'MANUAL' &&
          this.groupedData.length < 1) ||
        (this.selectedDashboardAssetFilter?.id === 'MANUAL' && this.selectedAssets.length < 1)
      )
    },
    dashboardTypes() {
      const enums = get(this.dashboardTypesAvailable, 'enumValues', [])
      return enums
        .map(e => {
          return {
            id: e.name,
            label: this.$t(`dashboard.types.${e.name.toLowerCase()}`),
          }
        })
        .filter(f => !f.id.includes('TEMPLATE'))
    },
    dashboardFilters() {
      const filterOptions = [...this.dashboardAssetFilters]
      const validSubsidiaries = this.mySubsidiaries.filter(subsidiary => subsidiary.assets.length > 0)
      if (validSubsidiaries.length > 0) {
        const uniqueSubsidiariesWithAssetsCount = new Set(this.mySubsidiaries.filter(sub => sub.assets.length > 0).map(sub => sub.id)).size
        filterOptions.push({
          id: 'USER_GROUP',
          label: 'USER_GROUP',
          count: uniqueSubsidiariesWithAssetsCount,
        })
      }
      return filterOptions.map(f => ({
        ...f,
        label: this.$t(`dashboard.filters.${f.id}`),
      }))
    },
    showAssetSelection() {
      const filter = get(this.selectedDashboardAssetFilter, 'id', 'NONE')
      const t = get(this.selectedDashboardType, 'id', 'NONE')
      return filter === 'MANUAL' && (t === 'SINGLE_ASSET' || t === 'SINGLE_ASSET_TEMPLATE' || (t === 'FLEET' && this.assets.length > 1))
    },
    placeholderValue() {
      return this.$tc(`selects.asset`, 1)
    },
    groupByPlaceholderValue() {
      let groupedPlaceholder
      switch (this.selectedDashboardAssetFilter?.id) {
        case 'SITE':
          groupedPlaceholder = this.$t('selects.site')
          break
        case 'OEM':
          groupedPlaceholder = this.$t('selects.oem')
          break
        case 'MODEL':
          groupedPlaceholder = this.$t('selects.model')
          break
        case 'ASSET_TYPE':
          groupedPlaceholder = this.$t('selects.type')
          break
        case 'USER_GROUP':
          groupedPlaceholder = this.$t('selects.usergroup')
          break
      }
      return groupedPlaceholder
    },
    assetsFiltered() {
      return this.assets.map(({ model, type, ...asset }) => {
        const oem = this.oemsMap.get(asset.manufacturerCuid)
        const site = this.assetsSiteMap.get(asset.id)
        const siteToShow = site
          ? { ...site, id: site.siteId }
          : { id: 'unknown', name: this.$tc('unknownSite', 1), __typename: 'AssetSite' }
        const modelToShow = model || { id: 'unknown', name: this.$t('unknownModel'), __typename: 'MachineModel' }
        const oemToShow = oem || { id: 'unknown', name: this.$t('unknownOEM'), alias: this.$t('unknownOEM'), __typename: 'OEM' }
        const typeToShow = type || { id: 'unknown', name: this.$t('unknownType'), __typename: 'MachineType' }
        return {
          site: siteToShow,
          model: modelToShow,
          oem: oemToShow,
          type: typeToShow,
          ...asset,
        }
      })
    },
    groupedAssetMetaData() {
      let groupedAssets
      let assetCounts = {}
      switch (this.selectedDashboardAssetFilter?.id) {
        case 'SITE':
          groupedAssets = this.assetsFiltered.map(({ site }) => site)
          break
        case 'OEM':
          groupedAssets = this.assetsFiltered.map(({ oem }) => oem)
          break
        case 'MODEL':
          groupedAssets = this.assetsFiltered.map(({ model }) => model)
          break
        case 'ASSET_TYPE':
          groupedAssets = this.assetsFiltered.map(({ type }) => type)
          break
        case 'USER_GROUP':
          groupedAssets = this.mySubsidiaries.filter(subsidiary => subsidiary.assets.length)
          break
      }
      // Count the number of assets in each category
      if (this.selectedDashboardAssetFilter?.id !== 'USER_GROUP') {
        groupedAssets.forEach(asset => {
          assetCounts[asset.id] = (assetCounts[asset.id] || 0) + 1
        })
      } else {
        groupedAssets.forEach(subsidiary => {
          assetCounts[subsidiary.id] = subsidiary.assets.length
        })
      }

      // Create an array with asset counts
      const groupedAssetsWithCounts = uniqBy(groupedAssets, 'id').map(asset => {
        return {
          ...asset,
          assetCount: assetCounts[asset.id] || 0,
        }
      })
      return orderBy(groupedAssetsWithCounts, 'name', 'asc')
    },
    mappedSubsidiaries() {
      return this.mySubsidiaries.map(subsidiary => ({
        ...subsidiary,
        assets: subsidiary.assets.map(asset => ({
          ...asset,
          subsidiaryId: subsidiary.id,
        })),
      }))
    },
    uniqueSelectedAssets() {
      return uniqBy(this.selectedAssets, 'id')
    },
  },
  methods: {
    handleAllQueriesLoaded() {
      this.selectedAssets = this.args.assetFilter === 'MANUAL' ? get(this.args, 'assets', []) : this.getSelectedAssetsByFilter()
    },
    setSelectedDashboardType(selected) {
      this.selectedDashboardType = selected
    },
    setSelectedDashboardAssetFilter(selected) {
      this.selectedDashboardAssetFilter = selected
      this.clearGroupBy()
      if (this.selectedDashboardAssetFilter.id === this.args.assetFilter) {
        this.groupedData = this.args.assetGroups.map(group => {
          return {
            id: group.groupType === 'MachineType' || group.groupType === 'MachineModel' ? parseInt(group.groupId) : group.groupId,
            name: group.groupName,
            __typename: group.groupType,
          }
        })
        this.selectedAssets = this.getSelectedAssetsByFilter()
      }
    },
    handleSelectAssets(asset, isBulk) {
      if (isBulk) {
        this.createAssetsList.push(...asset)
      } else {
        this.createAssetsList.push(asset)
      }
    },
    handleRemoveAssets(asset, isBulk) {
      if (isBulk) {
        asset.forEach(e => {
          const found = this.createAssetsList.find(({ id }) => e.id === id)
          if (found) {
            this.createAssetsList = this.createAssetsList.filter(({ id }) => e.id !== id)
          } else {
            this.removeAsssetsList.push(e)
          }
        })
      } else {
        const found = this.createAssetsList.find(({ id }) => asset.id === id)
        if (found) {
          this.createAssetsList = this.createAssetsList.filter(({ id }) => asset.id !== id)
        } else {
          this.removeAsssetsList.push(asset)
        }
      }
    },
    handleRemoveSubsidiaryAssets(assets, subsidiaryId) {
      assets.forEach(e => {
        const found = this.createAssetsList.find(({ id }) => e.id === id && e.subsidiaryId === subsidiaryId)
        if (found) {
          this.createAssetsList = this.createAssetsList.filter(
            asset => !(found.id === asset.id && asset.subsidiaryId === found.subsidiaryId),
          )
        } else {
          this.removeAsssetsList.push(e)
        }
      })
    },
    handleSelectedGroupedData(data) {
      this.createGroupByList.push(data)
      const filteredAssets = this.assetsGroupedByFilters(data)
      this.selectedAssets.push(...filteredAssets)
      this.handleSelectAssets(filteredAssets, true)
    },
    handleRemoveGroupedData(data) {
      const found = this.createGroupByList.find(({ id }) => data.id === id)
      if (found) {
        this.createGroupByList = this.createGroupByList.filter(({ id }) => data.id !== id)
      } else {
        this.removeGroupByList.push(data)
      }
      const filteredAssets = this.assetsGroupedByFilters(data)
      if (data.__typename === 'Subsidiary') {
        filteredAssets.map(e => {
          const found = this.selectedAssets.find(({ id }) => e.id === id && e.subsidiaryId === data.id)
          if (found) {
            this.selectedAssets = this.selectedAssets.filter(asset => !(found.id === asset.id && asset.subsidiaryId === found.subsidiaryId))
          }
        })
        this.handleRemoveSubsidiaryAssets(filteredAssets, data.id)
      } else {
        filteredAssets.map(e => {
          const found = this.selectedAssets.find(({ id }) => e.id === id)
          if (found) {
            this.selectedAssets = this.selectedAssets.filter(({ id }) => e.id !== id)
          }
        })
        this.handleRemoveAssets(filteredAssets, true)
      }
    },
    async clearAssets() {
      this.createAssetsList = []
      this.removeAsssetsList = this.removeAsssetsList.concat(this.selectedAssets)
      this.selectedAssets = []
    },
    async clearGroupBy() {
      this.createGroupByList = []
      this.removeGroupByList = this.removeGroupByList.concat(this.groupedData)
      this.groupedData = []
      this.clearAssets()
    },
    async createDashboard() {
      this.isCreating = true

      try {
        const t = get(this.selectedDashboardType, 'id', 'NONE')
        if (t === 'FLEET' && this.selectedAssets.length > 0 && this.selectedAssets.length < 2) {
          throw new Error(this.$t(`dashboard.errors.createError`))
        }
        const payload = {
          title: this.title,
          ownerUserId: getUserIdFromToken(this.$keycloak.token),
          type: get(this.selectedDashboardType, 'id', undefined),
          dashboardCreatedAt: DateTime.now(),
          assetFilter: get(this.selectedDashboardAssetFilter, 'id', 'NONE'),
          assetGroups: {
            create: this.createGroupByList.map(a => ({
              groupId:
                this.selectedDashboardAssetFilter.id === 'ASSET_TYPE' || this.selectedDashboardAssetFilter.id === 'MODEL'
                  ? `${a.id}`
                  : a.id,
              groupName: a.name,
              groupType: a.__typename,
            })),
          },
        }

        const data =
          this.selectedDashboardAssetFilter?.id === 'MANUAL'
            ? { ...payload, assignedAssets: { create: this.selectedAssets.map(a => ({ assetId: a.id, assetType: a.__typename })) } }
            : payload

        if (get(this.image, 'id', false)) {
          data.picture = {
            connect: {
              id: this.image.id,
            },
          }
        }

        const resp = await this.$apollo.mutate({
          mutation: CREATE_DASHBOARD_MUTATION,
          variables: {
            data,
          },
          update: (store, { data: { createDashboard } }) => {
            const data = store.readQuery({
              query: DASHBOARDS_QUERY,
            })
            data.dashboards.push(createDashboard)
            store.writeQuery({
              query: DASHBOARDS_QUERY,
              data,
            })
            store.writeQuery({
              query: DASHBOARDS_MINIMAL,
              data,
            })
          },
        })

        const id = get(resp, 'data.createDashboard.id')
        if (id) {
          this.$router.push({
            name: 'dashboard',
            params: {
              id,
            },
          })
          this.$emit('close')
        }
      } catch (err) {
        this.errors.push({
          id: nanoid(),
          rawError: err,
        })
        this.isCreating = false
      }
    },
    async updateDashboard() {
      this.isCreating = true
      const create = this.createAssetsList.map(a => ({ assetId: a.id, assetType: a.__typename }))
      const removeAssets = this.removeAsssetsList.map(a => a.id)

      const createAssetGroups = this.createGroupByList.map(a => ({
        groupId:
          this.selectedDashboardAssetFilter.id === 'ASSET_TYPE' || this.selectedDashboardAssetFilter.id === 'MODEL' ? a.id + '' : a.id,
        groupName: a.name,
        groupType: a.__typename,
      }))
      const removeAssetGroups = this.removeGroupByList.map(a =>
        a.__typename === 'MachineType' || a.__typename === 'MachineModel' ? a.id + '' : a.id,
      )

      try {
        const t = get(this.selectedDashboardType, 'id', 'NONE')
        if (t === 'FLEET' && this.selectedAssets.length > 0 && this.selectedAssets.length < 2) {
          throw new Error(this.$t(`dashboard.errors.createError`))
        }

        const data = {
          title: this.title,
          type: get(this.selectedDashboardType, 'id', undefined),
          assetFilter: get(this.selectedDashboardAssetFilter, 'id', 'NONE'),
        }
        const where = {
          id: this.args.id,
        }

        if (this.removeAsssetsList.length < 1 && this.createAssetsList.length < 1) {
          await this.updateDashboardMutation(data, where)
        }

        if (get(this.image, 'id', false)) {
          data.picture = {
            connect: {
              id: this.image.id,
            },
          }
        }

        if (this.removeAsssetsList?.length > 0 || this.removeAssetGroups?.length > 0) {
          if (
            (this.removeAsssetsList.length > 0 && this.selectedDashboardAssetFilter?.id === 'MANUAL') ||
            this.args.assetFilter === 'MANUAL'
          ) {
            data.assignedAssets = {
              deleteMany: {
                assetId_in: removeAssets,
              },
            }
          }
          if (this.removeGroupByList.length > 0) {
            data.assetGroups = {
              deleteMany: {
                AND: {
                  groupId_in: removeAssetGroups,
                  groupType: this.removeGroupByList[0].__typename,
                },
              },
            }
          }
          await this.updateDashboardMutation(data, where)
        }

        if (this.createAssetsList?.length > 0 || this.createAssetGroups?.length > 0) {
          if (this.createAssetsList.length > 0 && this.selectedDashboardAssetFilter?.id === 'MANUAL') {
            data.assignedAssets = {
              create,
            }
          }
          if (this.createGroupByList.length > 0) {
            data.assetGroups = {
              create: createAssetGroups,
            }
          }
          await this.updateDashboardMutation(data, where)
        }

        this.$emit('close')
      } catch (err) {
        this.errors.push({
          id: nanoid(),
          rawError: err,
        })
        this.isCreating = false
      }
    },

    async updateDashboardMutation(data, where) {
      const dashboard = await this.$apollo.mutate({
        mutation: DASHBOARDS_UPDATE_MUTATION,
        variables: {
          data,
          where,
        },
      })
      this.selectedAssets = []
      this.selectedAssets =
        dashboard?.data?.updateDashboard?.assetFilter === 'MANUAL'
          ? dashboard?.data?.updateDashboard?.assets
          : this.getSelectedAssetsByFilter
      return dashboard
    },

    triggerFileUpload() {
      this.$refs.fileUpload.click()
    },
    async upload({ target: { files = [] } }) {
      if (!files.length) {
        return
      }
    },
    assetsGroupedByFilters(data) {
      let filteredAssets = null
      switch (this.selectedDashboardAssetFilter?.id) {
        case 'SITE':
          filteredAssets = this.assetsFiltered.filter(asset => asset.site.id === data.id)
          break
        case 'OEM':
          filteredAssets = this.assetsFiltered.filter(asset => asset.oem.id === data.id)
          break
        case 'MODEL':
          filteredAssets = this.assetsFiltered.filter(asset => asset.model.id === data.id)
          break
        case 'ASSET_TYPE':
          filteredAssets = this.assetsFiltered.filter(asset => asset.type.id === data.id)
          break
        case 'USER_GROUP':
          filteredAssets = this.mappedSubsidiaries.find(subsidiary => subsidiary.id === data.id)?.assets ?? []
          break
      }
      return filteredAssets
    },
    getSelectedAssetsByFilter() {
      const groupedAssets = []
      this.groupedData.forEach(group => {
        const filteredAssets = this.assetsGroupedByFilters(group)
        groupedAssets.push(...filteredAssets)
      })
      return groupedAssets
    },
  },
  apollo: {
    dashboardTypesAvailable: {
      query: DASHBOARD_TYPES_AVAILABLE_QUERY,
    },
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result() {
        this.queriesLoaded.assets = true
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
        this.queriesLoaded.myAssetsSite = true
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
        this.queriesLoaded.oems = true
      },
    },
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_WITH_ASSETS_QUERY,
      result() {
        this.queriesLoaded.mySubsidiaries = true
      },
    },
    dashboards: {
      query: DASHBOARDS_QUERY,
    },
  },
}
</script>
