var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardSettingsOverlayStyled', [_c('Modal', {
    staticClass: "modal",
    attrs: {
      "errors": _vm.errors
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t(_vm.headerTitle)) + " ")];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('FormStyled', [_c('FormRowStyled', [_c('InputStyled', [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.title.$model,
            expression: "$v.title.$model",
            modifiers: {
              "trim": true
            }
          }],
          ref: "nameInput",
          attrs: {
            "placeholder": _vm.$tc('name', 1)
          },
          domProps: {
            "value": _vm.$v.title.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.title, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        }), _vm.$v.title.$dirty && !_vm.$v.title.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(_vm.$tc('messages.required')))]) : _vm._e(), !_vm.$v.title.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(_vm.$t('messages.minText', {
          value: _vm.$v.title.$params.minLength.min
        })))]) : _vm._e()])], 1), _c('FormRowStyled', [_c('BasicSelectMolecule', {
          attrs: {
            "target": _vm.$tc('dashboard.types.placeholder'),
            "options": _vm.dashboardTypes,
            "selected": _vm.selectedDashboardType
          },
          on: {
            "change": _vm.setSelectedDashboardType
          }
        })], 1), _vm.$apollo.queries.assets.loading ? _c('TalpaLoaderWrapper', {
          staticClass: "loader"
        }) : [_c('FormRowStyled', [_c('BasicSelectMolecule', {
          attrs: {
            "target": _vm.$tc('dashboard.types.placeholderFilter'),
            "options": _vm.dashboardFilters,
            "selected": _vm.selectedDashboardAssetFilter
          },
          on: {
            "change": _vm.setSelectedDashboardAssetFilter
          }
        })], 1), _vm.selectedDashboardAssetFilter && _vm.selectedDashboardAssetFilter.id !== 'NONE' && _vm.selectedDashboardAssetFilter.id !== 'MANUAL' ? _c('FormRowStyled', [_c('Multiselect', {
          attrs: {
            "multiple": true,
            "options": _vm.groupedAssetMetaData,
            "loading": _vm.$apollo.queries.assets.loading,
            "showLabels": false,
            "close-on-select": false,
            "placeholder": _vm.groupByPlaceholderValue,
            "trackBy": "id",
            "label": "name"
          },
          on: {
            "select": _vm.handleSelectedGroupedData,
            "remove": _vm.handleRemoveGroupedData
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref) {
              var option = _ref.option;
              return [_c('div', {
                staticClass: "label"
              }, [_vm._v(_vm._s(option.name) + " "), _c('CircleBadge', {
                staticClass: "badge",
                attrs: {
                  "label": option.assetCount,
                  "size": "M"
                }
              })], 1)];
            }
          }], null, false, 1095498691),
          model: {
            value: _vm.groupedData,
            callback: function callback($$v) {
              _vm.groupedData = $$v;
            },
            expression: "groupedData"
          }
        }), _c('ClearAssetsStyled', {
          on: {
            "click": function click($event) {
              return _vm.clearGroupBy();
            }
          }
        }, [_c('XIcon')], 1)], 1) : _vm._e(), _vm.showAssetSelection ? _c('FormRowStyled', [_c('Multiselect', {
          attrs: {
            "multiple": true,
            "options": _vm.assets,
            "loading": _vm.$apollo.queries.assets.loading,
            "showLabels": false,
            "placeholder": _vm.placeholderValue,
            "close-on-select": false,
            "hideSelected": true,
            "trackBy": "id",
            "label": "name"
          },
          on: {
            "select": _vm.handleSelectAssets,
            "remove": _vm.handleRemoveAssets
          },
          model: {
            value: _vm.selectedAssets,
            callback: function callback($$v) {
              _vm.selectedAssets = $$v;
            },
            expression: "selectedAssets"
          }
        }), _c('ClearAssetsStyled', {
          on: {
            "click": _vm.clearAssets
          }
        }, [_c('XIcon')], 1)], 1) : _vm._e(), _vm.selectedDashboardAssetFilter && _vm.selectedDashboardAssetFilter.id === 'NONE' ? _c('InfoRowStyled', [_c('InfoIcon', {
          attrs: {
            "size": "1.5x"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('selectedAssetsInfo', {
          x: _vm.assets.length
        })) + " ")], 1) : _vm._e(), _vm.errorMessage ? _c('InfoRowStyled', [_c('InfoIcon', {
          attrs: {
            "size": "1.5x"
          }
        }), _vm._v(" " + _vm._s(_vm.errorMessage) + " ")], 1) : _vm._e(), _vm.uniqueSelectedAssets.length > 0 ? _c('FleetOverview', [_c('span', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.$tc('selectedAssets', _vm.uniqueSelectedAssets.length)) + " "), _c('CircleBadge', {
          staticClass: "badge",
          attrs: {
            "label": _vm.uniqueSelectedAssets.length,
            "size": "M"
          }
        })], 1), _c('ul', _vm._l(_vm.uniqueSelectedAssets, function (asset, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" " + _vm._s(asset.name) + " ")]);
        }), 0)]) : _vm._e(), false ? _c('FormRowStyled', [_vm.image && _vm.image.url ? _c('ImagePreviewStyled', [_c('img', {
          attrs: {
            "src": _vm.image.url
          }
        })]) : _c('FileInputStyled', {
          on: {
            "click": _vm.triggerFileUpload
          }
        }, [_c('ImageIcon'), _c('input', {
          ref: "fileUpload",
          attrs: {
            "type": "file"
          },
          on: {
            "change": _vm.upload
          }
        })], 1)], 1) : _vm._e()]], 2)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_vm.mode === 'create' ? _c('ButtonSolidStyled', {
          attrs: {
            "disabled": _vm.createDisabled
          },
          on: {
            "click": _vm.createDashboard
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.create')) + " ")]) : _c('ButtonSolidStyled', {
          attrs: {
            "disabled": _vm.createDisabled
          },
          on: {
            "click": _vm.updateDashboard
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")]), _c('ButtonBorderedStyled', {
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }